.app-loader-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9;
}

.app-loader {
    display: flex;
    margin: 0 auto;
}