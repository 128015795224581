.sidebar-width-250 {
    width: 250px !important;
}

.ant-layout-sider .ant-menu-inline .ant-menu-item {
    padding-left: 20px !important;
}

.ant-layout-sider .ant-menu-submenu>.ant-menu.ant-menu-sub .ant-menu-item {
    font-size: 12px !important;
    padding-left: 30px !important;
}

.ant-menu-dark .ant-menu-item-selected .anticon, .ant-menu-dark .ant-menu-item-selected .anticon+span{
    color: #FCB514;
}


.gx-site-logo img {
    width: 120px;
}

.max-width-150 {
    max-width: 150px;
}

.gx-app-sidebar .ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {
    left: auto;
    right: 22px;
}

.ant-layout-sider .ant-menu-inline .ant-menu-submenu-title {
    padding-left: 20px !important;
}

