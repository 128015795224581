.status-fields {
    background-color: #ffffff;
}

.btn-confirm-green .ant-btn-primary {
    color: #ffffff !important;
    background-color: #52c41a !important;
    border-color: #52c41a !important;
}

.btn-confirm-red .ant-btn-primary {
    color: #ffffff !important;
    background-color: #f44336 !important;
    border-color: #f44336 !important;
}

.quarter-dropdown {
    font-size: 12px;
    border-radius: 6%;
    border-color: lightgray;
    padding: 10%;
    outline: none;
    text-align: left;
    height: 40px;
}

.quarter-dropdown-container-admin-pdt {
    margin-right: 2%;
}

.quarter-dropdown-container-user-pdt {
    margin-left: 15px;
}
.quarter-dropdown-container-admin-consolidated-pdt{
    margin-right: 60px;
}