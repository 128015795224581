/* ant d */

.ant-table-thead > tr > th {
  background: rgba(233, 235, 243, 0.5);
}

.ant-btn.disabled,
.ant-btn:disabled {
  margin-bottom: 0;
}

.ant-tag {
  white-space: inherit;
}

/* ant d */

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.float-right {
  float: right;
}

.flex-d-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-d-row {
  display: flex;
  flex-direction: row;
}

.flex-d-column {
  display: flex;
  flex-direction: column;
}

.margin-0 {
  margin: 0 !important;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.padding-15 {
  padding: 15px !important;
}

.line-height-27 {
  line-height: 27px;
}

.text-capitalize {
  text-transform: capitalize;
}

.flex-wrap-none {
  flex-wrap: nowrap !important;
}

.width-30 {
  width: 30px;
}

.min-width-600 {
  min-width: 600px;
}

.height-100 {
  height: 100%;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-45 {
  font-size: 45px !important;
}

.color-red {
  color: #f44336;
}

.color-green {
  color: #52c41a;
}

.color-black {
  color: #000000;
}

.color-gray {
  color: #688782;
}

.color-cobalt {
  color: #0097f5;
}

.color-purple {
  color: #283a8a;
}

.color-blue {
  color: #00aeef;
}

a {
  color: #0060b6;
  text-decoration: none;
}

a:hover {
  color: #00a0c6;
  text-decoration: none;
  cursor: pointer;
}

.non-editable-cell {
  background-color: #eeeeee;
  color: #aaaaaa;
}

.error-cell {
  border-color: #ee0000 !important;
  color: red;
}

.valid-cell {
  color: var(--ag-data-color, var(--ag-foreground-color, #000));
  border-color: transparent !important;
}

/* ant d tables */

.mpp-list-table .ant-table-pagination.ant-pagination {
  margin: 16px 0 0 0;
}

.mpp-list-table .ant-pagination > li {
  margin-bottom: 0px;
}

#body1 {
  height: 100%;
}
.mpp-list-table .ant-table-small .ant-table-thead > tr > th {
  background-color: #ffffff;
}

.mpp-list-table table {
  table-layout: fixed !important;
}

/* ant d tables */

/* login page */

.gx-login-header img {
  max-width: 50%;
}

.ag-scroll-bar-fix {
  border: solid 1px transparent;
  border-color: #ffffff;
  /* border-color: var(--ag-border-color, #bdc3c7); */
}
