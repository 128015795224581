.people-info {
    width: 90%
}

.product-select {
    width: 90% !important;
}

.width-60 {
    width: 60% !important;
}