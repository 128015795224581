.chat-message {
  margin-bottom: 10px;
}

.chat-message-user {
  text-align: left;
}

.chat-message-partner {
  text-align: right;
}

.chat-message-content {
  background-color: #f6f6f6;
  padding: 8px;
  border-radius: 8px;
  display: inline-block;
}

.chat-timestamp {
  font-size: 12px;
  color: #999;
  margin-left: 8px;
}
