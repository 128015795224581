.empty-msg {
    font-size: 20px;
}

.sales-report-btn-save .ant-btn-primary:disabled {
    margin-bottom: 0;
    margin-right: 15px;
}

.ant-input-number {
    margin-right: 10px;
    height: 36px;
}

.quarter-dropdown {
    font-size: 12px;
    border-radius: 6%;
    border-color: lightgray;
    padding: 10%;
    outline: none;
    text-align: left;
    height: 40px;
}

.quarter-dropdown-container-admin-sales {
    margin-left: 0px;
}

.quarter-dropdown-container-user-sales {
    margin-left: 0px;
}

.request_id_btn {
    color: black;
    background-color: transparent;
    border-color: transparent;
    display: inline-block;
    font-weight: 500;
    width: 96%;
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
    padding: 0;
    min-height: 20px;
  }
.request_id_cross_btn {
    color: black;
    background-color: transparent;
    cursor: pointer;
    border-color: transparent;
    display: inline-block;
    font-weight: 500;
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
    padding: 0;
    min-height: 20px;
  }

  .menu-container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: absolute;
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    max-height: 500px;
    overflow-y: auto;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }

  .menu-item:hover {
    cursor: pointer;
    background: rgb(240, 240, 240);
  }

  .menu-item.active {
    background-color: #dc3545;
    border-color: #dc3545;
    color: white;
  }
  .menu-item {
    display: block;
    padding: 0.2rem 0.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    border: 0;
    border-right: solid 1px;
    flex: 1;
    transition: background 0.3s ease;
  }
  .menu-item.active {
    background-color: #dc3545;
    border-color: #dc3545;
    color: white;
  }
  .menu-item:hover {
    cursor: pointer;
    background: rgb(240, 240, 240);
  }
  .menu-parent {
    border: solid 1px;
    display: flex;
    flex: 0 0 80%;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  .menu-parent:hover {
    background: rgb(240, 240, 240);
    /* box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1); */
    transform: scale(1.02);
  }
  .menu-child {
    display: flex;
    padding: 0.2rem 0.5rem;
    flex-direction: column;
    flex: 0 0 20%;
  }
  .menu-subchild {
    display: flex;
    white-space: nowrap;
    /* padding: 0.2rem 0.5rem; */
    /* flex-direction: column; */
  }
  .menu-subchild-buyer-name {
    display: flex;
    flex-direction: row;
    max-width: 400px;
  }
  
  .buyer-name-key {
    max-width: 100px;
  }
  .buyer-name-value {
    margin-left: 10px;
  }
  .menu-container.open {
    animation: fadeIn 0.3s ease; /* Add a fade-in animation */
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Adjust this to fit your layout */
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

