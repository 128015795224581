.text-editor {
  width: 700px;
  margin-bottom: 50px;
}

.ck-editor__editable {
  min-height: 400px;
}

.text-editor-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
}

.extra-info-display{
  margin-right: 10px;
}

/* .table-info-wrapper, tr{
  border: 1px solid #d9d9d9;
} */