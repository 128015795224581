.mpp-list-table {
  width: 100%;
}

.mpp-list-table .ant-table-cell {
  padding: 8px 8px ;
  white-space: normal ;
  word-break: break-word;
  overflow: visible;
  line-height: 1.5;
}

.mpp-list-table .ant-table-thead .ant-table-cell {
  padding: 8px 8px;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
}

.ant-table-wrapper {
  width: 100%;
  overflow-x: hidden;
}

.mpp-list-table .ant-table-thead > tr > th,
.mpp-list-table .ant-table-tbody > tr > td {
  height: auto ;
  max-height: none ;
}

.mpp-list-table .ant-table-cell-content {
  white-space: normal ;
  word-wrap: break-word;
}

.mb-0 .ant-card-body {
  padding: 8px ;
}

.mpp-list-table.ant-table-wrapper .ant-card-body {
  padding: 8px ;
}